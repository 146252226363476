import React from 'react';
import LampDemo from './components/ui/Lamp'; // Ensure the path matches your file structure

const App = () => {
  return (
    <div className="App">
      {/* This component now contains all the necessary elements */}
      <LampDemo />
    </div>
  );
}

export default App;
