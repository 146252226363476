import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '../../utils/cn';

export const LampContainer = ({
    children,
    className,
  }) => {
    return (
      <div
        className={cn(
          "relative flex min-h-screen flex-col items-center justify-center overflow-hidden bg-slate-950 w-full rounded-md z-0",
          className
        )}
      >
        <div className="relative flex w-full flex-1 scale-y-125 items-center justify-center isolate z-0 ">
          <motion.div
            initial={{ opacity: 0.5, width: "10rem" }} // Smaller initial width for mobile
            whileInView={{ opacity: 1, width: "20rem" }} // Smaller animate width for mobile
            transition={{
              delay: 0,
              duration: 0.8,
              ease: "easeInOut",
            }}
            style={{
              backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
            }}
            className="absolute inset-auto right-1/2 h-56 overflow-visible w-[20rem] sm:w-[30rem] bg-gradient-conic from-cyan-500 via-transparent to-transparent text-white [--conic-position:from_70deg_at_center_top]"
          >
            <div className="absolute  w-[100%] left-0 bg-slate-950 h-40 bottom-0 z-20 [mask-image:linear-gradient(to_top,white,transparent)]" />
            <div className="absolute  w-40 h-[100%] left-0 bg-slate-950  bottom-0 z-20 [mask-image:linear-gradient(to_right,white,transparent)]" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0.5, width: "10rem" }} // Same changes for this motion.div
            whileInView={{ opacity: 1, width: "20rem" }} // Adjust width as needed
            transition={{
              delay: 0,
              duration: 0.8,
              ease: "easeInOut",
            }}
            style={{
              backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
            }}
            className="absolute inset-auto left-1/2 h-56 w-[20rem] sm:w-[30rem] bg-gradient-conic from-transparent via-transparent to-cyan-500 text-white [--conic-position:from_290deg_at_center_top]"
          >
            <div className="absolute  w-40 h-[100%] right-0 bg-slate-950  bottom-0 z-20 [mask-image:linear-gradient(to_left,white,transparent)]" />
            <div className="absolute  w-[100%] right-0 bg-slate-950 h-40 bottom-0 z-20 [mask-image:linear-gradient(to_top,white,transparent)]" />
          </motion.div>
        <div className="absolute inset-auto z-40 h-44 w-full -translate-y-[12.5rem] bg-slate-950 "></div>
      </div>
 
      <div className="relative z-50 flex -translate-y-80 flex-col items-center px-5">
        {children}
      </div>
    </div>
  );
};

export const LampDemo = () => {
    return (
      <LampContainer>
        <div className="relative z-50 flex flex-col items-center px-4 sm:px-8">
          <motion.h1
            initial={{ opacity: 0.5, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              delay: 0,
              duration: 0.8,
              ease: "easeInOut",
            }}
            className="text-5xl sm:text-5xl md:text-6xl lg:text-7xl text-white mb-4 sm:mb-6 md:mb-8"
          >
            Sirca
          </motion.h1>
          <motion.p
            initial={{ opacity: 0.5, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{
              delay: 0,
              duration: 0.8,
              ease: "easeInOut",
            }}
            className="whitespace-nowrap text-sm sm:text-lg md:text-xl text-white mb-6 sm:mb-8"
          >
            Building companies that make the world feel good.
          </motion.p>
          <div className="flex overflow-x-auto space-x-2 sm:space-x-3 md:space-x-4 mb-6 sm:mb-8">
            <a href="https://neuro.sirca.io/" className="text-xs sm:text-sm text-white hover:underline">Neuro</a>
            <span className="text-gray-400">|</span>
            <a href="https://verithene.com/" className="text-xs sm:text-sm text-white hover:underline">Verithene</a>
            <span className="text-gray-400">|</span>
            <a href="https://sirca.io/" className="text-xs sm:text-sm text-white hover:underline whitespace-nowrap">Secret 1</a>
            <span className="text-gray-400">|</span>
            <a href="https://sirca.io/" className="text-xs sm:text-sm text-white hover:underline whitespace-nowrap">Secret 2</a>
            <span className="text-gray-400">|</span>
            <a href="https://fastr.sirca.io" className="text-xs sm:text-sm text-white hover:underline">Fastr</a>
            <span className="text-gray-400">|</span>
            <a href="https://jetpoint.io" className="text-xs sm:text-sm text-white hover:underline">JetPoint</a>
          </div>
        </div>
      </LampContainer>
    );
};
  
  export default LampDemo;